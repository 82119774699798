<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">

                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.sort === false}"
                        class="form-label"
                        for="sort"
                    >{{ $t('addresses::address_types.columns.sort').ucFirst() }}</label
                    >
                    <input
                        id="sort"
                        v-model="model.sort"
                        :class="{'border-danger': states.number === false}"
                        :placeholder="$t('addresses::address_types.placeholders.sort').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.sort === false}" class="invalid-feedback">{{
                            errors.sort
                        }}
                    </div>
                </div>
                <div class="col-lg-9">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('addresses::address_types.columns.name').ucFirst() }} *</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('addresses::address_types.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "AddressTypeForm",

    emits: ['saved'],

    props: {
        addressType: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'addressType/loading',
            'states': 'addressType/states',
            'errors': 'addressType/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.addressType)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`addressType/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/address-types/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        addressType: function () {
            this.model = JSON.parse(JSON.stringify(this.addressType))
        },
    },

    created() {
        // console.log(this.addressType)
    }
}
</script>

<style scoped>

</style>
